<!--
 * @Description: 编辑退订规则
 * @Author: 琢磨先生
 * @Date: 2022-05-26 15:28:39
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-15 22:31:00
-->

<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="800px"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="图片" prop="pic_url">
        <upload-avatar
          :src="form.pic_url"
          @change="uploadChange"
        ></upload-avatar>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
        <div class="help-block">例如：灵活、宽松、适中、严格</div>
      </el-form-item>
      <el-form-item label="入住前n天前(天)" prop="before_day">
        <el-col :span="5">
          <el-input
            v-model="form.before_day"
            placeholder="请输入天数"
          ></el-input>
        </el-col>
        <div class="help-block">0代表入住当天，1代表入住前1天前，以此类推</div>
      </el-form-item>

      <el-form-item label="时分">
        <el-time-select
          v-model="form.time"
          start="01:00"
          step="01:00"
          end="23:00"
          placeholder="Select time"
        />
        <div class="help-block">例如：入住前1天12点前</div>
      </el-form-item>

      <el-form-item label="退款方式" prop="refund_way">
        <el-radio-group v-model="form.refund_way">
          <el-radio
            :label="item.value"
            v-for="item in enums.unsubscribe_refund_way"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
        <div class="help-block"></div>
      </el-form-item>
      <el-form-item label="之后退订扣费方式" prop="deduct_way">
        <el-radio-group v-model="form.deduct_way">
          <el-radio
            :label="item.value"
            v-for="item in enums.unsubscribe_deduct_way"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
        <div class="help-block"></div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
        <div class="help-block">停用后后续录入房源无法再选择</div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="loading"
          :loading="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import common_api from "@/http/common_api";
import UploadAvatar from "../../upload/upload_avatar.vue";
export default {
  components: {
    UploadAvatar,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      title: "",
      enums: {},
      form: {},
      rules: {
        pic_url: [
          {
            required: true,
            message: "图片必传",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        before_day: [
          {
            required: true,
            message: "天数必填",
            trigger: "blur",
          },
          {
            pattern: /^0|([1-9]\d*)$/,
            message: "正整数",
            trigger: "blur",
          },
        ],
        refund_way: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        deduct_way: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.title = "新增退订规则";
          this.form = Object.assign({}, this.item);
          if (this.item.id) {
            this.title = "修改退订规则";
          }
        }
      },
    },
  },
  created() {
    var dt = new Date("2022-01-10 00:00:00");
    console.log(dt);
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/unsubscribe/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 图片上传
     */
    uploadChange(url) {
      this.form.pic_url = url;
    },
  },
};
</script>